<template>
	<div class="mstp-map">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>设备管理</span>
				<div class="title-right">
					<ul>
						<li>主页</li>
						<li>/</li>
						<li>系统总览</li>
						<li>/</li>
						<li>首页</li>
					</ul>
				</div>
			</div>
			<div class="region">
				<div class="region_left region-all">
					<div class="myTitle">设备类型</div>
					<div class="myBody">
						<ul class="device_type">
							<li>路由器</li>
							<li>交换机</li>
							<li>服务器</li>
							<li>机房</li>
							<li>机柜</li>
							<li>电源</li>
							<li>数据库</li>
							<li>进程</li>
						</ul>
					</div>
				</div>
				<div class="region_center region-all">
					<div class="myTitle">设备</div>
					<div class="myBody">
						<div class="myBody-top">
							<span>设备ID</span>
							<span>设备名称</span>
						</div>
						<div class="btn">
							<span>编辑</span>
						</div>
					</div>
				</div>
				<div class="region_right region-all">
					<div class="myTitle">设备属性</div>
					<div class="myBody">
						<div class="myBody-top">
							<span>属性名</span>
							<span>属性值</span>
						</div>
						<div class="btn">
							<span>新增</span>
							<span>修改</span>
							<span>删除</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
export default {
	components: {
		sidebar,
		headerTop,
	},
}
</script>

<style lang="scss" scoped>
.mstp-map {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.region {
			display: flex;
			.region-all {
				flex: 1;
				color: #fff;
				font-size: 14px;
				margin: 0 5px;
				.myTitle {
					text-align: center;
					padding-top: 12px;
					padding-bottom: 6px;
				}
				.myBody {
					.device_type {
						margin-top: 15px;
						background-color: rgba(0, 0, 0, 0);
						display: flex;
						flex-direction: column;
						li {
							list-style: none;
							display: inline-block;
							width: 50%;
							height: 40px;
							line-height: 40px;
							/* padding-left: 15px; */
							text-align: center;
							border-top: 1px dotted #ccc;
							border-bottom: 1px solid #ccc;
							background-color: white;
							color: black;
						}
					}
					.myBody-top {
						span {
							background: rgba(0, 0, 0, 0.35);
							display: inline-block;
							width: 50%;
							padding: 5px 8px 6px;
						}
						span:first-child {
							border-right: 1px solid rgba(255, 255, 255, 0.1);
						}
					}
					.btn {
						span {
							width: 46px;
							height: 30px;
							color: #ddd;
							border: 1px solid rgba(255, 255, 255, 0.1);
							display: inline-block;
							text-align: center;
							line-height: 30px;
							border-radius: 4px;
							margin-top: 20px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
</style>
